import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';
import { useExposeAuthModal } from '~/hooks/useExposeAuthModal';
import { useGiftGiverReminderModal } from '~/components/common/modals/GiftGiverReminderModal/useGiftGiverReminderModal';
import { GiftGiverReminderModal } from '~/components/common/modals/GiftGiverReminderModal/GiftGiverReminderModal';
import _isEmpty from 'lodash/isEmpty';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
// import RenderTest from './RenderTest/RenderTest';

import featureFlags from '~/util/featureFlags';
import { useDispatch, useSelector } from 'react-redux';
import { getUserContext } from '~/selectors/user';
import { maybeFetchUser } from '~/actions/UserActions';

import './stylesheets/nav/WebsiteNav.less';
import './components/navV2/navV2.less';

const PublicRegistryNavV1 = loadable(
  () => import(/* webpackChunkName: "public-registry-nav" */ '~/components/publicRegistryNav')
);
const PublicRegistryNavV2 = loadable(
  () => import(/* webpackChunkName: "public-registry-nav" */ '~/components/publicRegistryNav/v2')
);

const ModalRoot = loadable(() => import('./components/common/ModalRoot'), { ssr: false });
const ToastsContainer = loadable(() => import('./components/toastsContainer'), { ssr: false });
const FeatureToggle = loadable(() => import('./components/FeatureToggle'), { ssr: false });
const SideDrawerRoot = loadable(() => import('./components/common/SideDrawerRoot'), { ssr: false });

const {
  EVENTS: { PUBLIC_REGISTRY_EVENT },
} = NAV_DATA;

function PublicRegistryApp() {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);
  const [isPublicRegistry, setIsPublicRegistry] = useState(false);
  const [isPublicRegistryV2, setIsPublicRegistryV2] = useState(true); // TODO: to be removed when the new UI is released

  useEffect(() => {
    const handleSetPublicRegistry = (event: Event) => {
      if ((event as CustomEvent)?.detail === 'EXIT') {
        setIsPublicRegistry(false);
        setIsPublicRegistryV2(false);
      }
      if ((event as CustomEvent)?.detail === 'ENTER_V2') {
        setIsPublicRegistryV2(true);
      } else {
        setIsPublicRegistry(true);
      }
    };
    window.addEventListener(PUBLIC_REGISTRY_EVENT, handleSetPublicRegistry);
    return function cleanup() {
      window.removeEventListener(PUBLIC_REGISTRY_EVENT, handleSetPublicRegistry);
    };
  }, [isPublicRegistry, isPublicRegistryV2, setIsPublicRegistry, setIsPublicRegistryV2]);

  const [showFeatureToggle, setShowFeatureToggle] = useState(false);
  const { registryObjectId, showGiftGiverReminderModal, setShowGiftGiverReminderModal } =
    useGiftGiverReminderModal({
      forPublicRegistry: true,
      userContext,
    });
  useForceUpdateOnWindowResize();
  useExposeAuthModal();

  useEffect(() => {
    setShowFeatureToggle(featureFlags.get('featureToggle'));
  }, []);

  useEffect(() => {
    if (_isEmpty(userContext)) {
      dispatch(maybeFetchUser());
    }
  }, [dispatch, userContext]);

  return (
    <React.Fragment>
      {/* <RenderTest /> */}
      <a href="/webaccessibility" tabIndex={0} className="accessibility-link screen-only">
        Web Accessibility
      </a>
      {isPublicRegistry && <PublicRegistryNavV1 />}
      {isPublicRegistryV2 && <PublicRegistryNavV2 />}
      <ModalRoot />
      <SideDrawerRoot />
      <ToastsContainer />
      {showFeatureToggle && <FeatureToggle />}
      {registryObjectId && showGiftGiverReminderModal ? (
        <GiftGiverReminderModal
          forPublicRegistry
          onClose={() => setShowGiftGiverReminderModal(false)}
          registryObjectId={registryObjectId}
        />
      ) : null}
    </React.Fragment>
  );
}

export default PublicRegistryApp;
